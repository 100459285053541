import 'shared/css/paf-bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.js';

import LoginFormTemplate from '../views/login-form.pug';

import PAF from 'shared/js/paf.js';
import * as loader from 'shared/js/loading-overlay';
import 'shared/css/paf-loader.css';

export default class {
  constructor({ loginRedirect = null, showRedirMsg = false }) {
    console.log('redirect? ', loginRedirect, decodeURIComponent(loginRedirect));
    if (loginRedirect) {
      loginRedirect = decodeURIComponent(loginRedirect);
      window.localStorage.setItem('login_redir', loginRedirect);
      this.loginRedirect = loginRedirect;
    }
    this.showRedirMsg = showRedirMsg;
  }

  redirect() {
    let page;
    const redir = window.localStorage.getItem('login_redir');
    if (redir) {
      page = $.Deferred().resolve(redir);
    } else {
      page = PAF.Query('project/getLandingPage');
    }
    page
      .then((landingPage) => {
        window.localStorage.removeItem('login_redir');
        window.location.replace(landingPage);
      })
      .catch((xhr) => {
        alert(xhr);
        console.log(xhr);
      });
  }

  doLogin(e) {
    e.preventDefault();
    loader.addLoadingAnimation('body');
    PAF.Query('user/login', { email: this.$usernameInput.val(), password: this.$passwordInput.val() }, true, true)
      .then(() => {
        this.redirect();
      })
      .catch((error) => {
        console.log('error! ', error);
        loader.removeLoadingAnimation();
        if (error.includes('Invalid Email')) {
          this.$wrongUsername.show();
        } else if (error.includes('Invalid Password')) {
          this.$wrongPassword.show();
        } else if (typeof error === 'string') {
          this.$notificationMessage.text(error);
        } else {
          console.log(`ERROR: ${JSON.stringify(error)}`);
        }
      });
  }

  // here we add the container and mount the necessary pieces to the proper container divs
  mount(mountSelector, title) {
    this.$loginApp = $(mountSelector);
    this.$loginApp.html(LoginFormTemplate({ title }));
    this.$loginButton = this.$loginApp.find('.login-button');
    this.$notificationMessage = this.$loginApp.find('.notification-message');
    this.$wrongUsername = this.$loginApp.find('.notification-email-username');
    this.$wrongPassword = this.$loginApp.find('.notification-password');

    this.$usernameInput = this.$loginApp.find('.email-input');
    this.$passwordInput = this.$loginApp.find('.pwd-input');

    this.$loginButton.on('click', (e) => this.doLogin(e));
    this.$loginApp.find('.login-form').submit((e) => this.doLogin(e));
    this.$loginApp.find('.pwd-input, .email-input').keyup((e) => {
      this.$wrongUsername.hide();
      this.$wrongPassword.hide();
      if (this.$usernameInput.val() === '' || this.$passwordInput.val() === '') this.$loginButton.prop('disabled', true);
      if (this.$usernameInput.val() !== '' && this.$passwordInput.val() !== '') this.$loginButton.prop('disabled', false);
      // Allow enter key to hit login
      if (e.keyCode === 13) {
        this.$loginButton.click();
      }
    });

    // $('.email-input').focus();
    if (this.showRedirMsg) {
      this.$notificationMessage.text('You must be logged in to access this page');
    }
  }
}

function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (background_image, bottom_left_logo_image, bottom_left_logo_link, bottom_right_logo_image, bottom_right_logo_link, contact_email, contact_name, top_left_logo_image, top_left_logo_link) {
      pug_html = pug_html + "\u003Cdiv class=\"login-wrapper container-fluid\" id=\"app-login\"\u003E\u003Cdiv class=\"row h-100 w-100\"\u003E\u003Cdiv class=\"col-sm-3\"\u003E\u003Ca" + (" class=\"bottom-left-logo-link\""+pug_attr("href", bottom_left_logo_link, true, true)) + "\u003E\u003Cimg" + (" class=\"bottom-left-logo\""+pug_attr("src", bottom_left_logo_image, true, true)) + "\u003E\u003C\u002Fa\u003E\u003Ca" + (" class=\"top-left-logo-link\""+pug_attr("href", top_left_logo_link, true, true)) + "\u003E\u003Cimg" + (" class=\"top-left-logo\""+pug_attr("src", top_left_logo_image, true, true)) + "\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-sm-6 align-self-center\"\u003E\u003Cdiv class=\"login-container form-signin\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-sm-3\"\u003E\u003Cdiv class=\"top-right-contacts\"\u003E\u003Ch4\u003EProject Contact\u003C\u002Fh4\u003E\u003Cp class=\"contact\"\u003E \u003Ca" + (pug_attr("href", `mailto:${contact_email}`, true, true)+" target=\"_top\"") + "\u003E" + (pug_escape(null == (pug_interp = contact_name) ? "" : pug_interp)) + " \u003C\u002Fa\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Ca" + (" class=\"bottom-right-logo-link\""+pug_attr("href", bottom_right_logo_link, true, true)) + "\u003E\u003Cimg" + (" class=\"bottom-right-logo\""+pug_attr("src", bottom_right_logo_image, true, true)) + "\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cimg" + (" class=\"background-image\""+pug_attr("src", background_image, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"loading-indicator\"\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "background_image" in locals_for_with ?
        locals_for_with.background_image :
        typeof background_image !== 'undefined' ? background_image : undefined, "bottom_left_logo_image" in locals_for_with ?
        locals_for_with.bottom_left_logo_image :
        typeof bottom_left_logo_image !== 'undefined' ? bottom_left_logo_image : undefined, "bottom_left_logo_link" in locals_for_with ?
        locals_for_with.bottom_left_logo_link :
        typeof bottom_left_logo_link !== 'undefined' ? bottom_left_logo_link : undefined, "bottom_right_logo_image" in locals_for_with ?
        locals_for_with.bottom_right_logo_image :
        typeof bottom_right_logo_image !== 'undefined' ? bottom_right_logo_image : undefined, "bottom_right_logo_link" in locals_for_with ?
        locals_for_with.bottom_right_logo_link :
        typeof bottom_right_logo_link !== 'undefined' ? bottom_right_logo_link : undefined, "contact_email" in locals_for_with ?
        locals_for_with.contact_email :
        typeof contact_email !== 'undefined' ? contact_email : undefined, "contact_name" in locals_for_with ?
        locals_for_with.contact_name :
        typeof contact_name !== 'undefined' ? contact_name : undefined, "top_left_logo_image" in locals_for_with ?
        locals_for_with.top_left_logo_image :
        typeof top_left_logo_image !== 'undefined' ? top_left_logo_image : undefined, "top_left_logo_link" in locals_for_with ?
        locals_for_with.top_left_logo_link :
        typeof top_left_logo_link !== 'undefined' ? top_left_logo_link : undefined));
    ;;return pug_html;};module.exports=template;
import 'bootstrap';
import loadingTemplate from '../views/loading-overlay.pug';
import '../css/paf-loader.css';

// add a overlaying loading div with animated spiral and some text:
// TODO this styling is super hacky. Put it in CSS
export const addLoadingAnimation = (div_to_add_on, text_content) => {
  $('.loading-div').remove();
  $(div_to_add_on).append(loadingTemplate({ text_content }));
};

export const updateLoadingAnimationText = (text_content) => {
  $('.loading-div-row span').html(`\n${text_content}`);
};
// remove/hide loading selector
export const removeLoadingAnimation = () => {
  $('.loading-div').remove();
};

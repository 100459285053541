import 'shared/css/paf-bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.js';

import LoginArea from './js/login-area.js';
import LoginForm from './js/login-form.js';
import 'shared/css/login.css';

import PAF from 'shared/js/paf.js';

class LoginPage {
  constructor({ loginRedirect, showRedirMsg }) {
    this.loginForm = new LoginForm({ loginRedirect, showRedirMsg });
    this.loginArea = new LoginArea();
  }

  redirect() {
    this.loginForm.redirect();
  }

  // here we add the container and mount the necessary pieces to the proper container divs
  mount(mountSelector) {
    this.loginArea.mount(mountSelector, this.loginForm);
  }
}

// this runs at startup and gets everything going
$(() => {
  const params = PAF.getUrlVars();

  const loginPage = new LoginPage({
    loginRedirect: params.redir,
    showRedirMsg: params.show_redir_msg,
  });
  // set storage
  // Wasn't sure the best way to do this, but this works -Doug
  PAF.Query('user/isLoggedIn', {}, true).then((loggedIn) => {
    if (loggedIn) {
      loginPage.redirect();
    } else {
      PAF.Query('user/getOauthUrl', {}, true).then((oauthUrl) => {
        // If we don't have oauth url then we just mount the login page locally
        // Otherwise we redirect to the oauth point (which is
        if (!oauthUrl) {
          loginPage.mount('.page-wrapper');
        } else {
          window.location.replace(oauthUrl);
        }
      });
    }
  });
});

import 'shared/css/paf-bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.js';

import LoginAreaTemplate from '../views/login-area.pug';

import PAF from 'shared/js/paf.js';
import * as loader from 'shared/js/loading-overlay';
import 'shared/css/paf-loader.css';

export default class {
  // here we add the container and mount the necessary pieces to the proper container divs
  mount(mountSelector, form) {
    // get the login background image, contacts , etc
    loader.addLoadingAnimation(mountSelector);
    PAF.Query('project/getProjectInfo')
      .then((projectInfo) => {
        console.log('project ? ', projectInfo, 'projectInfo length: ', projectInfo.length);
        // /deal with projectInfo ==  []

        if (projectInfo.description) {
          $('.login-title').text(projectInfo.description);
        }

        // default no/white background
        let backgroundImage;// = "/assets/login-background-small.jpg";
        if (projectInfo.project_background_url) {
          backgroundImage = projectInfo.project_background_url;
        }
        if (projectInfo.length === 0) {
          const firstSubdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'barley';
          console.log('first Sub domain: ', firstSubdomain);
          backgroundImage = `/assets/endpoints/backgrounds/${firstSubdomain}.jpg`;
        }

        let bottomLeftLogoLink = '';
        // no project, show Roelof's image
        let bottomLeftLogoImage = '/assets/website-figure.png';
        if (projectInfo.project_logo_url) {
          bottomLeftLogoImage = projectInfo.project_logo_url;
          bottomLeftLogoLink = projectInfo.project_url;
        }

        // we can have a logo in the top left corner
        // logo_topleft_url
        let topLeftLogoLink = '';
        let topLeftLogoImage = '';
        // no project, show Roelof's image
        if (projectInfo.project_logo_topleft_url) {
          topLeftLogoImage = projectInfo.project_logo_topleft_url;
          if (topLeftLogoImage == '/assets/projects/usgsertmonitoring/epalogo.png') {
            topLeftLogoLink = 'https://www.epa.gov/';
          }
        }

        /* eslint-disable spellcheck/spell-checker */
        let contactEmail = 'roelof.versteeg@subsurfaceinsights.com';
        let contactName = 'Roelof Versteeg';
        /* eslint-enable spellcheck/spell-checker */
        if (projectInfo.contact_name && projectInfo.contact_email) {
          contactEmail = projectInfo.contact_email;
          contactName = projectInfo.contact_name;
        }
        const loginAreaTemplate = LoginAreaTemplate({
          background_image: backgroundImage,
          contact_email: contactEmail,
          contact_name: contactName,
          bottom_right_logo_image: '/assets/SubsurfaceInsights_Logo_Final.png',
          bottom_right_logo_link: 'https://www.subsurfaceinsights.com',
          top_left_logo_image: topLeftLogoImage,
          top_left_logo_link: topLeftLogoLink,
          bottom_left_logo_link: bottomLeftLogoLink,
          bottom_left_logo_image: bottomLeftLogoImage,
        });
        loader.removeLoadingAnimation(mountSelector);
        $(mountSelector).html(loginAreaTemplate);
        form.mount('.login-container', projectInfo.description);
      });
  }
}
